<template>
  <div id=''>
    <el-card>
      <el-row :gutter="20" class="operate">
        <el-col :span="4">
          <el-input v-model="selectName" placeholder="请输入配置值名称" clearable></el-input>
        </el-col>
        <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
        <el-button @click="openDialog(form, true)" type="primary">添加值</el-button>
        <el-button @click="batchDelete" icon="el-icon-delete" type="danger"></el-button>
      </el-row>
      <el-table v-loading="loading" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" stripe border>
        <el-table-column fixed="left" type="selection" width="50"></el-table-column>
        <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
        <el-table-column prop="name" label="名称" width="200"></el-table-column>
        <el-table-column prop="alias" label="别名" width="200"></el-table-column>
        <el-table-column prop="value" label="配置值" width="200"></el-table-column>
        <el-table-column prop="other" label="其它"></el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" @change="changeStatus(scope.row)" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{scope.row.status | statusText}}</span>
          </template>
        </el-table-column>
        <el-table-column label="排序" width="200">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.sort" @change="changeSort(scope.row)" :controls="false" :min="0" size="small"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template #default="scope">
            <el-button @click="openDialog(scope.row, false)" type="warning" size="mini">修改</el-button>
            <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>

      <!-- 添加弹窗 -->
      <el-dialog @close="closeDialog('addProfileValueItem')" title="添加配置值" :visible.sync="showDialog" class="add_dialog">
        <el-form :model="form" ref="addProfileValueItem" :rules="rules">
          <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
            <el-col :span="16">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="别名" prop="alias" :label-width="formLabelWidth">
            <el-col :span="16">
              <el-input v-model="form.alias" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="值" prop="value" :label-width="formLabelWidth">
            <el-col :span="16">
              <el-input v-model="form.value" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="其它" prop="other" :label-width="formLabelWidth">
            <el-col :span="16">
              <el-input v-model="form.other" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
            <el-col :span="8">
              <el-input-number v-model="form.sort" autocomplete="off" :min="0" :controls="false"></el-input-number>
            </el-col>
          </el-form-item>
          <el-form-item label="状态" :label-width="formLabelWidth">
            <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{form.status | statusText}}</span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button v-if="isAddFlag" @click="addItem('addProfileValueItem')" type="primary">确 定</el-button>
          <el-button v-else @click="editItem('addProfileValueItem')" type="primary">确 定</el-button>
        </div>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
    import {getProfileValueList, addProfileValueItem, editProfileValueItem, deleteProfileValue, deleteProfileValueItem} from '@api';
    export default {
        name: 'ProfileValue',
        components: {},
        data() {
            return {
                params: { // 请求分页参数
                    ids: [],
                    page: 1,
                    page_size: 20
                },
                total: 0, // 数据总条数
                tableData: [], // 表格数据
                loading: true, // 表格加载等待
                showDialog: false, // 是否显示弹窗
                isAddFlag: false, // 是否点击添加用户
                form: { // 添加表单
                    name: '',
                    alias: '',
                    value: '',
                    other: '',
                    sort: 0,
                    status: 0,
                },
                initForm: {
                    name: '',
                    alias: '',
                    value: '',
                    other: '',
                    sort: 0,
                    status: 0,
                },
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ],
                    value: [
                        {required: true, message: '请输入配置值', trigger: 'blur'},
                    ],
                },
                formLabelWidth: '120px', // from表单label的宽度
                profileId: 0,
                selectName: '',
                multipleSelection: [],
            }
        },
        created() {
            this.profileId = parseInt(this.$route.params.id);
            this.getData(this.params);
        },
        filters: {
            statusText(status) {
                if (status > 0) {
                    return '启用';
                }
                return '禁用';
            }
        },
        methods:{
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            search() { // 搜索
                const {selectName, profileId} = this;
                this.params.profile_id = profileId;
                this.params.name = selectName;
                this.getData(this.params);
            },
            addItem(refName) { // 添加
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    this.form.status = parseInt(this.form.status);
                    this.form.profile_id = this.profileId;
                    addProfileValueItem(this.form).then(() => {
                        this.showDialog = false;
                        this.getData(this.params);
                        this.$message.success('添加成功!');
                        this.initFormData();
                    });
                });
            },
            editItem(refName) { // 修改单条数据
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    const {form, form: {id}} = this;
                    form.status = parseInt(form.status);
                    const body = form;
                    body.fields = Object.keys(this.initForm);
                    editProfileValueItem(id, body).then(() => {
                        this.showDialog = false;
                        this.$message.success('修改成功！');
                        this.getData(this.params);
                    });
                });
            },
            deleteItem(id) { // 删除单挑数据
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteProfileValueItem(id).then(() => {
                        this.$message.success('删除成功!');
                        this.getData(this.params);
                    });
                }).catch(() => this.$message.info('已取消删除'));
            },
            batchDelete() {
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.multipleSelection.forEach(item => {
                        this.params.ids.push(item.id);
                    });
                    deleteProfileValue(this.params).then(() => {
                        this.$message.success('删除成功!');
                        this.getData(this.params);
                    });
                }).catch(() => this.$message.info('已取消删除'));
            },
            changeStatus(item) {
                item.fields = ["status"];
                item.status = item.status ? 1 : 0;
                editProfileValueItem(item.id, item).then(() => {
                    this.$message.success('修改成功！');
                    this.getData(this.params);
                });
            },
            changeSort(item) {
                item.fields = ["sort"];
                editProfileValueItem(item.id, item).then(() => {
                    this.$message.success('修改成功！');
                    this.getData(this.params);
                })
            },
            openDialog(item, isAdd) { // 打开弹窗
                this.form = { ...item };
                this.showDialog = true;
                this.isAddFlag = isAdd;
            },
            closeDialog(refName) { // 关闭弹窗
                this.showDialog = false;
                this.$refs[refName].resetFields();
                this.initFormData();
            },
            cancel() {
                this.showDialog = false;
            },
            handleCurrentChange(val) { // 获取页数
                this.params.page = val;
                this.getData(this.params);
            },
            handleSizeChange(val) { // 获取每页条数
                this.params.page_size = val;
                this.getData(this.params);
            },
            getData(params) {
                this.loading = true;
                params.profile_id = this.profileId;
                getProfileValueList(params.profile_id, params).then(res => {
                    this.loading = false;
                    const {data, count} = res.data;
                    this.total = count;
                    this.tableData = data;
                }).catch(() => {
                    this.loading = false;
                })
            },
            initFormData() { // 初始化数据
                this.form = this.initForm;
            }
        },
    }
</script>

<style lang='less' scoped>

</style>